import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import Hero, { Overlap } from '../components/Hero'
import Radio from '../components/Radio'
import Checkbox from '../components/Checkbox'
import Input from '../components/Input'
import Container from '../components/Container'
import Button from '../components/Button'
import Section from '../components/Section'
import ContactCard from '../components/ContactCard'
import Modal from '../components/Modal'
import ContainerList from '../components/ContainerList'
import Form, { FormGroup } from '../components/Form'

import containerTypes from '../dummy/container-types'

export default class Prices extends Component {
  state = {
    dangerousGoods: false,
    showPalletsModal: null,
  }

  toggle = (key, value) => () => {
    this.setState({
      [key]: value,
    })
  }

  getURLdata = () => {
    const { location } = this.props

    const from =
      location.search &&
      location.search
        .split('?from=')
        .join('')
        .split('&')[0]
    const to = location.search && location.search.split('&to=')[1].split('&')[0]
    const date =
      location.search && location.search.split('&date=')[1].split('&')[0]

    return location.search
      ? {
          from,
          to,
          date,
        }
      : null
  }

  render() {
    const {
      data: { hero },
    } = this.props

    const { dangerousGoods, showPalletsModal } = this.state

    return (
      <>
        <Hero {...hero} title="Request booking">
          <Container small>
            <FormGroup title="Selected route and time">
              <Button to="/">Change</Button>
            </FormGroup>
          </Container>
        </Hero>

        <Overlap>
          <Form>
            <FormGroup title="Containers">
              <ContainerList
                onChange={() => {}}
                containerTypes={containerTypes}
              />
              {/* TODO: Move into container list */}
              <Checkbox
                label="Dangerous goods"
                checked={dangerousGoods}
                onChange={this.toggle('dangerousGoods', !dangerousGoods)}
                spaceless={!dangerousGoods}
              />
              {dangerousGoods && (
                <Input
                  label="UN number"
                  placeholder="UN0000"
                  info="Please provide the UN number that specify the type of dangours goods
                in your shipment."
                  short
                  spaceless
                />
              )}

              <p style={{ marginTop: '1.5rem' }}>
                Please see our guide on{' '}
                <Link to="/services/equipment/">Containers and Equipment</Link>{' '}
                for selecting the right containers, or ask us for help.
              </p>
            </FormGroup>

            <FormGroup title="Transport options">
              <Radio
                label="Conventional"
                defaultChecked
                name="method"
                info="Pallets are transported from the pick up point by car and loaded
              into containers at the port by our staff."
              />
              <Radio
                label="Container direct (+ 800 EUR)"
                name="method"
                info="Empty containers are transported to the pick up point where
              pallets can be loaded into the container by the client."
              />
            </FormGroup>

            <FormGroup title="Customer">
              <Radio label="Existing customer" defaultChecked name="customer" />
              <Radio label="New customer" name="customer" />
              <Input label="Your email" placeholder="your@email.com" />
              <p>
                Your e-mail will only be used for this communication regarding
                this booking. Read our{' '}
                <Link to="/privacy/">Privacy policy</Link> for how we take care
                of your data.
              </p>
            </FormGroup>

            <Input
              label="Custom descriptions (optional)"
              type="textarea"
              placeholder=""
            />
            <p>
              We will manually go trough your request and finalize the booking
              for you. You will get a final booking confirmation from us that
              you will need to confirm to get the booking to be set into motion.
            </p>
            <Button primary>Request booking</Button>
          </Form>
        </Overlap>

        <Section type="light">
          <h2>Custom booking</h2>
          <p>
            Allready a customer? You can always book trough our email{' '}
            <a href="mailto:booking@ncl.no">booking@ncl.no</a> and we will help
            you.
          </p>
          <p>
            Please contact <a href="mailto:shortsea@ncl.no">shortsea@ncl.no</a>{' '}
            if you are new to NCL and want to register as a customer.
          </p>
        </Section>

        <Section>
          <h2>Questions or need of tailored solutions?</h2>
          <p>Talk with our sales manager Bente about door to door logistics.</p>
          <ContactCard who="bente-hetland" />
        </Section>

        <Modal
          show={!!showPalletsModal}
          from={showPalletsModal}
          handleClose={this.toggle('showPalletsModal', null)}
          title="Send this offer to my email"
          actions={[
            { name: 'Send offer', onClick: () => {}, secondary: true },
            {
              name: 'Cancel',
              onClick: this.toggle('showPalletsModal', null),
              tertiary: true,
            },
          ]}
        >
          <Input type="email" label="Your e-mail" autoFocus />

          <p>
            Your e-mail will only be used for this purpose. Read our{' '}
            <Link to="/privacy/">Privacy policy</Link> for how we take care of
            your data.
          </p>
        </Modal>
      </>
    )
  }
}

Prices.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.any,
}

export const query = graphql`
  query {
    hero: imageSharp(fluid: { originalName: { regex: "/farger/" } }) {
      fluid(maxWidth: 1400) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
