import './index.scss'

import ReactDOM from 'react-dom'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BEMHelper from 'react-bem-helper'

import Icon from '../Icon'
import Button from '../Button'

import { animateFrom, animateTo } from '../../helpers/animate'

const bem = new BEMHelper('modal')

const EASING_ENTER = `cubic-bezier(0.165, 0.84, 0.44, 1)`
const EASING_EXIT = `cubic-bezier(0.77, 0, 0.175, 1)`
const KEY_ESC = 27
const ANIMATION_TIME = 400

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.any,
    actions: PropTypes.array,
    title: PropTypes.string,
    show: PropTypes.bool,
    medium: PropTypes.bool,
    handleClose: PropTypes.func.isRequired,
  }

  static defaultProps = {
    handleClose: () => {},
  }

  constructor (props) {
    super(props)

    this.state = {
      domNode: null,
      expanded: false,
      animation: null,
      animateOut: false,
    }

    this.storeFromElement = null
    this.animateOutTimeout = null

    this.renderModal = this.renderModal.bind(this)
  }

  componentDidMount () {
    const domNode = document.querySelector('main')

    if (domNode) {
      this.setState({ domNode })
    }

    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEY_ESC) {
      this.toggle(false)()
    }
  }

  toggle = value => () => {
    if (this.state.expanded !== value) {
      this.setState(
        { expanded: value, animateOut: value === false },
        this.handleAnimation(value),
      )
    }
  }

  handleAnimation = show => () => {
    clearTimeout(this.animateOutTimeout)

    if (!this.storeFromElement || !this.modal) {
      this.setState({ animation: null, animateOut: false })
    }

    if (show) {
      document.body.classList.add('block-scrolling')

      this.modal.focus()

      this.setState({
        animation: animateFrom({
          from: this.storeFromElement,
          to: this.modal,
          fromProps: { opacity: 0 },
        }),
      })
    } else if (!show) {
      this.setState({
        animation: animateTo({
          from: this.modal,
          to: this.storeFromElement,
          toProps: { opacity: 0 },
        }),
      })

      this.animateOutTimeout = setTimeout(() => {
        document.body.classList.remove('block-scrolling')

        this.setState({ animation: null, animateOut: false })

        if (!show) {
          this.props.handleClose()
        }
      }, ANIMATION_TIME)
    }
  }

  renderModal () {
    const { animation, animateOut } = this.state
    const { children, title, actions, medium } = this.props

    return (
      <aside {...bem('', { medium })}>
        <button
          type="button"
          {...bem('hidden-close')}
          onFocus={this.toggle(false)}
          aria-label="Close"
        />

        <div
          {...bem('content')}
          tabIndex="0"
          style={{
            animation: `${animation} ${ANIMATION_TIME}ms ${
              animateOut ? EASING_EXIT : EASING_ENTER
            } both`,
          }}
          ref={ref => {
            this.modal = ref
          }}
        >
          <button
            type="button"
            {...bem('close')}
            onClick={this.toggle(false)}
            aria-label="Close"
          >
            <Icon icon="x" />
          </button>
          {title && <h2 {...bem('title')}>{title}</h2>}
          {children}
          {actions && (
            <nav {...bem('actions')}>
              {actions.map(({ name, ...props }, index) => (
                <Button {...props} key={index}>
                  {name}
                </Button>
              ))}
            </nav>
          )}
        </div>

        <button
          type="button"
          aria-label="Close"
          {...bem('backdrop', { 'animate-out': animateOut })}
          onClick={this.toggle(false)}
          onFocus={this.toggle(false)}
        />
      </aside>
    )
  }

  render () {
    const { expanded, animateOut, domNode } = this.state

    if (domNode && (expanded || animateOut)) {
      return ReactDOM.createPortal(this.renderModal(), domNode)
    }

    return null
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(props) {
    if (props.show !== this.state.expanded) {
      if (props.show) {
        this.storeFromElement = props.from || document.activeElement
      }

      this.toggle(props.show)()
    }
  }
}
