export default [
  {
    name: "45' dry container",
    value: '45',
    price: 1300,
    dangerousGoods: 120,
  },
  {
    name: "40' dry container",
    value: '40',
    price: 1200,
    dangerousGoods: 100,
  },
  {
    name: "20' dry container",
    value: '20',
    price: 1100,
    dangerousGoods: 100,
  },
  {
    name: "45' reefer (refrigiated)",
    value: '45-reefer',
    price: 1150,
    dangerousGoods: 150,
  },
  {
    name: "20' flat rack",
    value: '20-feet-flat-rack',
    price: 1200,
    dangerousGoods: 100,
  },
  {
    name: "40' flat rack",
    value: '40-feet-flat-rack',
    price: 1250,
    dangerousGoods: 100,
  },
  {
    name: "40' flat rack high cube",
    value: '40-feet-flat-rack-high',
    price: 1300,
    dangerousGoods: 100,
  },
]
